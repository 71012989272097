import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {Button, Container, Grid, Message } from 'semantic-ui-react';
import { navigate } from 'gatsby';
import { StoryblokLink } from '../../types';

interface OwnProps {
    title: string;
    description: string;
    callToActionText: string;
    callToActionLink: StoryblokLink;
}

export interface MessageBoxProps {
    blok: SbEditableContent & OwnProps;
}

export const MessageBox: React.FC<MessageBoxProps> = (props: MessageBoxProps) => {
    const { blok } = props;

    return (
        <Container className="tw-mb-28">
            <SbEditable key={props.blok._uid} content={props.blok}>
                <Message className="background">
                    <Grid stackable verticalAlign="middle" centered columns={2}>
                        <Grid.Column>
                            <Message.Header>{props.blok.title}</Message.Header>
                            <p>{props.blok.description}</p>
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            <Button secondary onClick={()=>navigate(props.blok.callToActionLink.cached_url)}>{props.blok.callToActionText}</Button>
                        </Grid.Column>
                    </Grid>
                </Message>
            </SbEditable>
        </Container>
    );
};