import { ContactEdit } from "./types";

export async function sendEmail(data: ContactEdit, locale: string, recaptchaToken: string): Promise<Response> {
    return fetch(process.env.GATSBY_CONTACT_ENDPOINT, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
            ...data,
            locale: locale, 
            token: recaptchaToken 
        })
    });
}