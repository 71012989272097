import React, { FormEvent, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { Form, Header, Segment } from 'semantic-ui-react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { defaultLocale, useLocale } from '../I18nProvider';
import { Tooltip } from '../Tooltip';
import { ContactEdit } from './types';
import * as api from './api';
import { SuccessMessage } from './successMessage';
import { FailureMessage } from './failureMessage';
import StoryblokService from '../../utils/storyblok-service';
import styles from './contactForm.module.css';

interface OwnProps {
    formTitle: string;
    formEmailLabel: string;
    formEmailInformation: string;
    formSubjectLabel: string;
    formMessageLabel: string;
    formSendButton: string;
    emailSentMessage: string;
    emailFailureMessage: string;
    recaptchaTerms: string;
}

export interface ContactFormProps {
    blok: SbEditableContent & OwnProps;
}

export const ContactForm: React.FC<ContactFormProps> = (props: ContactFormProps) => {
    const { blok } = props;
    const { register, reset, handleSubmit, formState: { errors } } = useForm<ContactEdit>();

    const { executeRecaptcha } = useGoogleReCaptcha();
    const currentLocale = useLocale();
    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);

    const recaptchaTerms = StoryblokService.renderRichText(blok.recaptchaTerms);

    useEffect(() => setIsReCaptchaLoaded(executeRecaptcha != null), [executeRecaptcha]);

    const submitForm = async (data: ContactEdit) => {
        setIsSending(true);
        setHasError(false);
        setHasSuccess(false);

        const currentLocaleCode = currentLocale === defaultLocale ? 'en' : 'fr';
        const recaptchaToken = await executeRecaptcha();

        api.sendEmail(data, currentLocaleCode, recaptchaToken)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    setHasSuccess(true);
                    reset();
                } else {
                    throw new Error(`Sending email failed with status code ${response.status}.`);
                }
            })
            .catch(() => setHasError(true))
            .finally(() => setIsSending(false));
    }

    return (
        <SbEditable key={props.blok._uid} content={props.blok}>
            <Segment loading={isSending} raised padded>
                <Header as="h2" className="tw-font-medium">{blok.formTitle}</Header>

                <Form onSubmit={handleSubmit(submitForm)}>
                    <Form.Field required error={errors.email}>
                        <label>{blok.formEmailLabel} <Tooltip content={blok.formEmailInformation} /></label>
                        <input type="email" {...register("email", { required: true })} />
                    </Form.Field>

                    <Form.Field error={errors.subject}>
                        <label>{blok.formSubjectLabel}</label>
                        <input type="text" {...register("subject")} />
                    </Form.Field>

                    <Form.Field required error={errors.message}>
                        <label>{blok.formMessageLabel}</label>
                        <textarea {...register("message", { required: true })} />
                        <p className={`${styles.recaptchaTerms} tw-text-xs tw-leading-normal`} dangerouslySetInnerHTML={{ __html: recaptchaTerms }} />
                    </Form.Field>

                    <Form.Field>
                        {hasSuccess && <SuccessMessage content={blok.emailSentMessage} />}
                        {hasError && <FailureMessage content={blok.emailFailureMessage} />}
                    </Form.Field>

                    <div className="tw-flex tw-justify-end">
                        <Form.Button primary disabled={!isReCaptchaLoaded || isSending} loading={isSending} fluid type="submit" className="tw-w-2/5 tall">
                            {blok.formSendButton}
                        </Form.Button>
                    </div>
                </Form>
            </Segment>
        </SbEditable>
    );
};