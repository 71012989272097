import { Link } from "gatsby";
import React from "react";
import { Menu } from "semantic-ui-react";
import { NavigationItem } from "../../models";
import { LanguageSwitcher } from "../LanguageSwitcher";

interface OwnProps {
    items: NavigationItem[];
}

export type FooterMenuProps =
    & OwnProps;

export const FooterMenu: React.FC<FooterMenuProps> = (props) => {

    const renderSubMenuItem = (item: NavigationItem) => {
        return (
            <Menu.Item key={item._uid}>
                {item.title}

                <Menu.Menu>
                    {item.childs.map((item) => renderNavigationItem(item))}
                </Menu.Menu>
            </Menu.Item>
        )
    }

    const renderNavigationItem = (item: NavigationItem) => {
        const { link } = item;
        const isAnchorLink = link.linktype === "url" && link.cached_url.startsWith("#");
        const linkUrl = link.linktype === "story" && !link.cached_url.startsWith('/') ? `/${link.cached_url}` : link.cached_url;
        
        return isAnchorLink
            ? <a className="item" key={item._uid} href={linkUrl}>{item.title}</a>
            : <Link className="item" key={item._uid} to={linkUrl}>{item.title}</Link>;
    };

    const renderMenuItem = (item: NavigationItem) => {
        const { childs } = item;
        
        return childs?.length > 0
            ? renderSubMenuItem(item)
            : renderNavigationItem(item);
    };

    return (
        <Menu vertical compact secondary>
            {props.items.map(renderMenuItem)}
            <LanguageSwitcher className="item"/>
        </Menu>
    );
};