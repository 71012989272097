import React from "react";
import { Icon, Popup } from "semantic-ui-react";

interface OwnProps {
    content: string;
}

export type TooltipProps =
    & OwnProps;

export const Tooltip: React.FC<TooltipProps> = (props) => {
    const tooltipIcon = <Icon color="black" name="question circle" />;

    return (
        <Popup content={props.content} trigger={tooltipIcon} />
    );
};