import React from "react";
import { Settings } from "../../models";
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';
import { Container, Divider, Grid, Item, Menu } from "semantic-ui-react";
import StoryblokService from '../../utils/storyblok-service';
import { FooterMenu } from "./footerMenu";
import { ContactItem } from "../../models/ContactItem";

import styles from "./pageFooter.module.css";

interface PageFooterProps {
    settings: Settings;
}

export const PageFooter: React.FC<PageFooterProps> = (props: PageFooterProps) => {
    const { logo, slogan, navigationMenu, contactInformation } = props.settings;
    const footerContent = StoryblokService.renderRichText(props.settings.footerCallToActionText);
     
    const renderContactItem = (item: ContactItem) => {
        const htmlContent = StoryblokService.renderRichText(item.content);

        return (
            <Item key={item._uid}>
                <div className="ui image">
                <img 
                    src={StoryblokRenderingService.resize(item.icon, 24, 0)} 
                    alt={item.icon.alt} 
                    title={item.icon.title} />
                </div>
                <div className={`left middle aligned content ${styles.contactItem}`} dangerouslySetInnerHTML={{ __html: htmlContent }}/>
            </Item>
        );
    };

    return (
        <Container>
            <footer className="page-footer">
                <Divider section />
                <Grid>
                    <Grid.Column floated='left' tablet={6} computer={6} only="tablet">
                        <img className="tw-mb-8" src={StoryblokRenderingService.resize(logo, 168, 0)} alt={logo.alt} title={logo.title} />
                        <p className="tw-leading-none tw-text-manatee">{slogan}</p>
                    </Grid.Column>
                    <Grid.Column floated='right' mobile={16} tablet={8} computer={7}>
                        <Grid columns={2}>
                            <Grid.Column>
                                <FooterMenu items={navigationMenu} />
                            </Grid.Column>
                            <Grid.Column>
                                <div className={`tw-text-manatee ${styles.compact}`} dangerouslySetInnerHTML={{ __html: footerContent }} />
                                <Item.Group>
                                    {contactInformation.map(renderContactItem)}
                                </Item.Group>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </footer>
        </Container>

    );
};
