import React, { useState } from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import {Accordion as SemanticAccordion } from 'semantic-ui-react';
import { AccordionPanel, AccordionPanelProps } from './accordionPanel';

interface OwnProps {
    panels: AccordionPanelProps[];
}

export interface AccordionProps {
    blok: SbEditableContent & OwnProps;
}

export const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
    const { blok } = props;
    const [activeIndex, setActiveIndex] = useState<number>(0);
    
    const handleAccordionClick = (index: number) => {
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex);
    }

    return (
        <SbEditable key={props.blok._uid} content={props.blok}>
            <SemanticAccordion>
                {blok.panels.map((panel, index) => <AccordionPanel key={panel._uid} index={index} activeIndex={activeIndex} handleAccordionClick={handleAccordionClick} {...panel} />)}
            </SemanticAccordion>
        </SbEditable>
    );
};