import React from 'react'
import { Container, Header } from 'semantic-ui-react';
import SbEditable, { SbEditableContent } from 'storyblok-react';

interface OwnProps {
    header: string;
    subHeader: string;
}

export interface PageTitleProps {
    blok: SbEditableContent & OwnProps;
}

export const PageTitle: React.FC<PageTitleProps> = (props: PageTitleProps) => {
    const { blok } = props;

    return (
        <Container textAlign="center">
            <SbEditable key={props.blok._uid} content={props.blok}>
                <Header as="h1" inverted>{props.blok.header}</Header>
                <p className="tw-text-2xl tw-text-white">{props.blok.subHeader}</p>
            </SbEditable>
        </Container>
    );
};