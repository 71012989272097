import { Link, navigate } from "gatsby";
import React from "react";
import { Button, Dropdown, Menu, Sidebar } from "semantic-ui-react";
import { NavigationItem } from "../../models";
import { useLayout } from "../Layout/layoutProvider";

interface OwnProps {
    items: NavigationItem[];
    className?: string;
    isHidden: boolean;
    callToAction?: NavigationItem;
}

export type MobileNavigationMenuProps =
    & OwnProps;

export const MobileNavigationMenu: React.FC<MobileNavigationMenuProps> = (props) => {
    const layout = useLayout();
    const handleCallToActionClick = () => {
        if (props.callToAction != null) {
            navigate(`/${props.callToAction.link.cached_url}`);
        }

        layout.setMobileMenuVisibility(false);
    }

    const renderSubMenuItem = (item: NavigationItem) => {
        return (
            <Menu.Item className="tw-py-4" key={item._uid}>
                {item.title}
                <Menu.Menu>
                    {item.childs.map((item) => {
                        return (
                            renderNavigationItem(item)
                        );
                    })}
                </Menu.Menu>
            </Menu.Item>
        )
    }

    const renderNavigationItem = (item: NavigationItem) => {
        const { link } = item;
        const isAnchorLink = link.linktype === "url" && link.cached_url.startsWith("#");
        const linkUrl = link.linktype === "story" && !link.cached_url.startsWith('/') ? `/${link.cached_url}` : link.cached_url;
        
        return isAnchorLink
            ? <a key={item._uid} className="item tw-block tw-py-4" href={linkUrl}>{item.title}</a>
            : <Link key={item._uid} className="item tw-block tw-py-4" to={linkUrl}>{item.title}</Link>;
    };

    const renderMenuItem = (item: NavigationItem) => {
        const { childs } = item;
        
        return childs?.length > 0
            ? renderSubMenuItem(item)
            : renderNavigationItem(item);
    };

    return (
        <Sidebar as={Menu} inverted direction="top" vertical animation="overlay" visible={props.isHidden}>
            {props.items.map(renderMenuItem)}

            {props.callToAction &&
                <Menu.Item>
                    <Button secondary onClick={handleCallToActionClick}>
                        {props.callToAction.title}
                    </Button>
                </Menu.Item>
            }
        </Sidebar>
    );
};