import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { defaultLocale, useLocale } from "../I18nProvider";
import { StoryblokEntry } from "../../types";
import { useLocation } from "@reach/router";

interface LanguageSwitcherProps {
    className?: string,
}

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props: LanguageSwitcherProps) => {
    const { pathname } = useLocation();
    const { allStoryblokEntry } = useStaticQuery<StoryblokEntry>(graphql`
        query {
            allStoryblokEntry {
                edges {
                  node {
                    name
                    lang
                    full_slug
                  }
                }
              }
        }`);

    const currentLocale = useLocale();
    let currentPageName = allStoryblokEntry.edges.find(edge => edge.node.lang === currentLocale && `/${edge.node.full_slug}` === pathname)?.node?.name;
    let nextSlug = currentLocale === defaultLocale ? "fr-ca" : "";
    if(!currentPageName)
    {
        currentPageName = "Home";
    }
    else
    {
        nextSlug = allStoryblokEntry.edges.find(edge => edge.node.lang !== currentLocale && edge.node.name === currentPageName).node.full_slug;
    }
    
    const nextLocaleName = currentLocale === defaultLocale ? "FR" : "EN";

    return (
        <>
            <Link to={`/${nextSlug}`} className={props.className}>{nextLocaleName}</Link>
        </>
    );
};