import React from 'react'
import { Container, Header } from 'semantic-ui-react';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { ServiceOverview, ServiceOverviewProps } from './serviceOverview';

interface OwnProps {
    title: string;
    services: ServiceOverviewProps[];
}

export interface FeaturedServicesProps {
    blok: SbEditableContent & OwnProps;
}

export const FeaturedServices: React.FC<FeaturedServicesProps> = (props) => {
    const { blok } = props;

    return (
        <SbEditable key={blok._uid} content={blok}>
            <Container className="tw-mt-48">
                <Header as="h3" className="tw-text-center tw-uppercase tw-text-manatee tw-mb-14">{blok.title}</Header>
                <div className="tw-flex tw-flex-row tw-flex-wrap sm:tw-flex-nowrap">
                    {blok.services.map(service => <ServiceOverview key={service._uid} {...service} />)}
                </div>
            </Container>
        </SbEditable>
    );
};