import React from "react"
import SbEditable from 'storyblok-react'
import { Helmet } from 'react-helmet'
import StoryblokService from '../utils/storyblok-service'
import { DynamicComponent } from "../components/DynamicComponent/dynamicComponent"
import { I18nProvider } from "../components/I18nProvider"
import { Layout } from "../components/Layout"
import { Seo } from "../types"
import { LayoutProvider } from "../components/Layout/layoutProvider"

export default class extends React.Component<any> {
    state = {
        story: {
            content: this.props.pageContext.story ? JSON.parse(this.props.pageContext.story.content) : {}
        }
    }

    async getInitialStory() {
        StoryblokService.setQuery(this.props.location.search)
        let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.pageContext.story.full_slug}`)
        return story
    }

    async componentDidMount() {
        let story = await this.getInitialStory()
        if (story.content) this.setState({ story })
        setTimeout(() => StoryblokService.initEditor(this), 200)
    }

    render() {
        const page = this.state.story.content;
        const content = page.body && page.body.map(component => <DynamicComponent blok={component} key={component._uid} />)
        const background = page.background as string;
        const seo = page.seo as Seo;

        return (
            <I18nProvider>
                <LayoutProvider>
                    <Helmet>
                        <title>{seo?.title || "ARIV" }</title>
                        <meta name="description" content={seo?.description || ""} />
                    </Helmet>

                    <Helmet
                        script={[
                            {"src": `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`, 
                            "type": "text/javascript"}
                        ]}
                    />

                    <Helmet
                        script={[
                            {
                            "innerHTML": `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
                            "type": "text/javascript"
                            }
                        ]}
                    />

                    <Layout background={background}>
                        <SbEditable content={page}>
                            {content || null}
                        </SbEditable>
                    </Layout>
                </LayoutProvider>
            </I18nProvider>
        )
    }
}