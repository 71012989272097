import React from "react";
import { Icon, Message } from "semantic-ui-react";

interface OwnProps {
    content: string;
}

export type SuccessMessageProps =
    & OwnProps;

export const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
    return (
        <Message success icon visible className="tw-leading-normal">
            <Icon name="check circle" />
            <Message.Content>
                {props.content}
            </Message.Content>
        </Message>
    );
};