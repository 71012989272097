import { navigate } from 'gatsby';
import React from 'react'
import { Button, Container, Grid, Header } from 'semantic-ui-react';
import { SbEditableContent } from 'storyblok-react';
import { StoryblokImage, StoryblokLink } from '../../types';

interface OwnProps {
    headline: string;
    text: string;
    image: StoryblokImage;
    callToActionText: string;
    callToActionLink: StoryblokLink;
}

export interface TeaserProps {
    blok: SbEditableContent & OwnProps;
}

export const Teaser: React.FC<TeaserProps> = (props: TeaserProps) => {
    const { blok } = props;

    return (
        <Container>
            <Grid stackable columns={2}>
                <Grid.Column>
                    <Header as='h1' inverted>{props.blok.headline}</Header>
                    <p className="tw-text-white tw-font-bold tw-mt-14">{props.blok.text}</p>
                    <Button className="tall tw-mt-14" primary onClick={()=>navigate(props.blok.callToActionLink.cached_url)}>{props.blok.callToActionText}</Button>
                </Grid.Column>
                <Grid.Column>
                    <img 
                        src={blok.image.filename} 
                        title={blok.image.title} 
                        alt={blok.image.title} 
                        className="tw-max-w-none tw-hidden sm:tw-block"
                    />
                </Grid.Column>
            </Grid>
        </Container>
    );
};