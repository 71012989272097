import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { Item } from 'semantic-ui-react';
import { ContactItem } from '../../models/ContactItem';
import StoryblokService from '../../utils/storyblok-service';
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';

import styles from "./contactInformation.module.css";

interface OwnProps {
    items: ContactItem[];
}

export interface ContactInformationProps {
    blok: SbEditableContent & OwnProps;
}

export const ContactInformation: React.FC<ContactInformationProps> = (props: ContactInformationProps) => {

    const renderContactItem = (item: ContactItem) => {
        const { icon } = item;
        const htmlContent = StoryblokService.renderRichText(item.content);

        return (
            <Item key={item._uid} className={`tw-flex tw-items-center ${styles.item}`}>
                <div className="ui image mini">
                    <img 
                        src={StoryblokRenderingService.resize(icon, 0, 0)} 
                        alt={icon.alt} 
                        title={icon.title} />
                </div>
                <div className={`middle aligned content ${styles.content}`} dangerouslySetInnerHTML={{ __html: htmlContent }}/>
            </Item>
        );
    };

    return (
        <SbEditable key={props.blok._uid} content={props.blok}>
            <Item.Group className={`tw-flex tw-flex-col tw-justify-center ${styles.contactInformation}`}>
                {props.blok.items.map(renderContactItem)}
            </Item.Group>
        </SbEditable>
    );
};