import React from "react";
import { Icon, Message } from "semantic-ui-react";

interface OwnProps {
    content: string;
}

export type FailureMessageProps =
    & OwnProps;

export const FailureMessage: React.FC<FailureMessageProps> = (props) => {
    return (
        <Message error icon visible className="tw-leading-normal">
            <Icon name="times circle" />
            <Message.Content>
                {props.content}
            </Message.Content>
        </Message>
    );
};