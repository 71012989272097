import React from 'react'
import { Placeholder } from '../Placeholder'
import { StoryblokComponent } from 'storyblok-js-client';
import { FeaturedServices } from '../FeaturedServices';
import { Teaser } from '../Teaser';
import { Header } from '../Header';
import { Image } from '../Image';
import { Grid } from '../Grid';
import { GridColumn } from '../Grid/gridColumn';
import { RichText } from '../RichText';
import { Accordion } from '../Accordion';
import { MessageBox } from '../MessageBox';
import { PageTitle } from '../PageTitle';
import { Biography } from '../Biography';
import { ContactInformation } from '../ContactInformation';
import { ContactForm } from '../ContactForm/contactForm';

const SupportedComponents = {
    'accordion': Accordion,
    'biography': Biography,
    'contact-form': ContactForm,
    'contact-information': ContactInformation,
    'featured-services': FeaturedServices,
    'grid': Grid,
    'grid-column': GridColumn,
    'header': Header,
    'image': Image,
    'message-box': MessageBox,
    'page-title': PageTitle,
    'rich-text': RichText,
    'teaser': Teaser
};

interface OwnProps {
    blok: StoryblokComponent<string>;
}

export type DynamicComponentProps =
    & OwnProps;

export const DynamicComponent: React.FC<DynamicComponentProps> = (props) => {
    if (typeof SupportedComponents[props.blok.component] !== 'undefined') {
        const Component = SupportedComponents[props.blok.component]
        return <Component blok={props.blok} key={props.blok._uid} />
    }

    return <Placeholder componentName={props.blok.component} />
};