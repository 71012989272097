import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Settings } from "../../models";
import { StoryblokEntry } from "../../types";
import { useLocale } from "../I18nProvider";
import { PageFooter } from "../PageFooter";
import { PageHeader } from "../PageHeader";

import "semantic-ui-less/semantic.less";
import { LayoutContext, LayoutProvider, useLayout } from "./layoutProvider";
import { useContext } from "react";
import { Sidebar } from "semantic-ui-react";

interface LayoutProps {
    background: string;
    children: any;
}

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { allStoryblokEntry } = useStaticQuery<StoryblokEntry>(graphql`
        query {
            allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
                edges {
                    node {
                        name
                        full_slug
                        content
                        lang
                    }
                }
            }
        }`
    );

    const locale = useLocale();
    const layout = useLayout();
    const jsonSettings = (allStoryblokEntry.edges.find(edge => edge.node.lang === locale) || allStoryblokEntry.edges[0]).node.content;
    const currentSettings = JSON.parse(jsonSettings) as Settings;

    return (
        <div className={"tw-w-full tw-text-primary background " + props.background}>
            <PageHeader settings={currentSettings} />

            <Sidebar.Pushable>
                <Sidebar.Pusher dimmed={layout.isMobileMenuVisible} onClick={() => layout.setMobileMenuVisibility(false) }>
                    <div>
                        <main>{props.children}</main>
                    </div>

                    <PageFooter settings={currentSettings} />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    );
};