import React from 'react'
import { Header as SemanticHeader } from 'semantic-ui-react';
import { SbEditableContent } from 'storyblok-react';

interface OwnProps {
    content: string;
    size: string;
    cssClasses: string;
}

export interface HeaderProps {
    blok: SbEditableContent & OwnProps;
}

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const { blok } = props;

    return (
        <SemanticHeader as={blok.size} className={blok.cssClasses} >
            {blok.content}
        </SemanticHeader>
    );
};