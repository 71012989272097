import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { StoryblokImage, StoryblokLink } from '../../types';
import StoryblokService from '../../utils/storyblok-service';
import { InternalLink } from '../InternalLink';
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';

import styles from "./serviceOverview.module.css";

interface OwnProps {
    title: string;
    image: StoryblokImage;
    description: string;
    callToActionText: string;
    callToActionLink: StoryblokLink;
}

export type ServiceOverviewProps =
    & SbEditableContent 
    & OwnProps;

export const ServiceOverview: React.FC<ServiceOverviewProps> = (props) => {
    const htmlDescription = StoryblokService.renderRichText(props.description);

    return (
        <SbEditable key={props._uid} content={props}>
            <div className={`tw-h-full tw-text-center tw-p-4 tw-mx-auto ${styles.solutionSummary}`} style={{ maxWidth: '250px' }}>
                <div className="tw-flex tw-justify-center">
                    <img 
                        src={StoryblokRenderingService.resize(props.image, 0, 130)} 
                        alt={props.image.alt} 
                        title={props.image.title} />
                </div>
                <h2 className="tw-font-medium">{props.title}</h2>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: htmlDescription }} />

                <InternalLink className="tw-block tw-mt-6" to={props.callToActionLink} text={props.callToActionText} />
            </div>
        </SbEditable>
    );
};