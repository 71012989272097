import React from 'react'
import { SbEditableContent } from 'storyblok-react';
import { StoryblokImage } from '../../types';
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';

interface OwnProps {
    image: StoryblokImage;
    height: number;
    width: number;
    cssClasses: string;
}

export interface ImageProps {
    blok: SbEditableContent & OwnProps;
}

export const Image: React.FC<ImageProps> = (props: ImageProps) => {
    const { blok } = props;

    return (
        <img 
            src={StoryblokRenderingService.resize(blok.image, blok.width, blok.height)} 
            alt={blok.image.alt} 
            title={blok.image.title} 
            className={blok.cssClasses}/>
    )
};