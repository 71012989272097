import { Link } from "gatsby";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { NavigationItem } from "../../models";

interface OwnProps {
    items: NavigationItem[];
    className?: string;
    isHidden: boolean;
}

export type NavigationMenuProps =
    & OwnProps;

export const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {

    const renderSubMenuItem = (item: NavigationItem) => {
        return (
            <Dropdown key={item._uid} text={item.title} className='link item'>
                <Dropdown.Menu>
                    {item.childs.map((item) => renderNavigationItem(item))}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const renderNavigationItem = (item: NavigationItem) => {
        const { link } = item;
        const isAnchorLink = link.linktype === "url" && link.cached_url.startsWith("#");
        const linkUrl = link.linktype === "story" && !link.cached_url.startsWith('/') ? `/${link.cached_url}` : link.cached_url;
        
        return isAnchorLink
            ? <a key={item._uid} className="item" href={linkUrl}>{item.title}</a>
            : <Link key={item._uid} className="item" to={linkUrl}>{item.title}</Link>;
    };

    const renderMenuItem = (item: NavigationItem) => {
        const { childs } = item;
        
        return childs?.length > 0
            ? renderSubMenuItem(item)
            : renderNavigationItem(item);
    };

    return (
        <>
            {props.items.map(renderMenuItem)}
        </>
    );
};