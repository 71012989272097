import React from "react";
import { Container } from "semantic-ui-react";
import SbEditable, { SbEditableContent } from "storyblok-react";
import { StoryblokContent } from "../../types";
import { DynamicComponent } from "../DynamicComponent/dynamicComponent";

export interface GridProps {
    blok: SbEditableContent & OwnProps;
}

interface OwnProps {
    background: string;
    columns?: StoryblokContent[];
    cssClasses: string;
}

const numberToTextMapping = new Map<number, string>([
    [1, "one"],
    [2, "two"],
    [3, "three"],
    [4, "four"],
    [5, "five"],
    [6, "six"],
    [7, "seven"],
    [8, "eight"],
    [9, "nine"],
    [10, "ten"],
    [11, "eleven"],
    [12, "twelve"]
]);

export const Grid: React.FC<GridProps> = (props) => {
    const { blok, children, ...otherProps } = props;
    const renderedChildren = children || blok.columns?.map(component => <DynamicComponent key={component._uid} blok={component} />);
    const columnsCount = blok.columns?.length || 1;
    const columnsCountCss = numberToTextMapping.get(columnsCount);

    return (
        <SbEditable content={blok} key={blok._uid}>
            <section className={`${blok.background || ''}`} >
                <Container>
                    <div className={`ui mobile reversed stackable ${columnsCountCss} column grid ${blok.cssClasses || ''}`}>
                        {renderedChildren}
                    </div>
                </Container>
            </section>
        </SbEditable>
    );
};