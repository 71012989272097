import React from 'react'

interface OwnProps {
    componentName: string;
}

export type PlaceholderProps =
    & OwnProps;

export const Placeholder: React.FC<PlaceholderProps> = (props) => {
    return (
        <div className="tw-py-4 tw-border tw-border-red-200 tw-bg-red-100">
            <p className="tw-text-red-700 tw-italic tw-text-center">
                The component <strong>{props.componentName}</strong> has not been created yet.
            </p>
        </div>
    );
};