import { StoryblokImage } from "../types";

  const imageService = '//img2.storyblok.com/';
  const imageReplace = '//a.storyblok.com';

  class StoryblokRenderingService {
 
    resize(image: StoryblokImage, width:number, height:number) {
      if(!image?.filename)
      {
        return "";
      }
      
      var res = image.filename.split(imageReplace);
      return imageService + (width || "0") + "x" + (height || "0")  + res[1]
    }
  }
  
  const storyblokRendering = new StoryblokRenderingService()
  
  export default storyblokRendering