import React, { useContext, useEffect, useState } from "react";

export const defaultLocale: string = "default"; // The default locale is specified in Storyblok as en-ca
export const supportedLocales: string[] = ["default", "fr-ca"];

export const I18nContext = React.createContext(defaultLocale);
export const useLocale = (): string => useContext(I18nContext);

export const I18nProvider: React.FC = (props) => {
    const [currentLocale, setLocale] = useState(defaultLocale);

    useEffect(() => {
        // The current user's locale is provided by the page URL.
        // E.g. /fr-ca/a-propos => fr-ca locale
        const currentPath = new URL(window.location.href).pathname;
        const splittedPath = currentPath.split('/');
        const requestedLanguage = splittedPath.length > 1 ? splittedPath[1] : undefined;
        const locale = supportedLocales.includes(requestedLanguage) ? requestedLanguage : defaultLocale;

        setLocale(locale);

    }, [typeof window !== `undefined` ? window.location.href : null]);

    return (
        <I18nContext.Provider value={currentLocale}>
            {props.children}
        </I18nContext.Provider>
    );
};