import React from "react";
import { Link, navigate } from "gatsby";
import { Settings } from "../../models";
import { NavigationMenu } from "../NavigationMenu";
import { LanguageSwitcher } from "../LanguageSwitcher";
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';
import { Button, Grid, Icon, Menu } from "semantic-ui-react";

import styles from "./pageHeader.module.css";
import { MobileNavigationMenu } from "../NavigationMenu/mobileNavigationMenu";
import { useLayout } from "../Layout/layoutProvider";
import { defaultLocale, useLocale } from "../I18nProvider";

interface PageHeaderProps {
    settings: Settings;
}

export const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
    const { logo } = props.settings;
    const layout = useLayout();
    const currentLocale = useLocale();
    const homePageLink = currentLocale === defaultLocale ? "/" : "/fr-ca";

    const ensureAbsoluteLink = (link: string) => link.startsWith('/') ? link : '/' + link;
    const handleClickOnStartYourProject = () => navigate(`${ensureAbsoluteLink(props.settings.startYourProjectButton[0].link.cached_url)}`);
    const toggleMobileMenu = () => layout.setMobileMenuVisibility(!layout.isMobileMenuVisible);

    return (
        <header className={styles.pageHeader}>
            <Grid>
                <Grid.Row only="computer">
                    <Grid.Column>
                        <Menu inverted secondary>
                            <Menu.Item>
                                <Link to={homePageLink} aria-label={logo.alt}>
                                    <img src={StoryblokRenderingService.resize(logo, 168, 0)} alt={logo.alt} title={logo.title} className="tw-mt-2"/>
                                </Link>
                            </Menu.Item>
                            <Menu.Menu position='right'>
                                <NavigationMenu
                                    items={props.settings.navigationMenu}
                                    isHidden={false}
                                />
                                <LanguageSwitcher className="item" />
                                <Menu.Item>
                                    <Button secondary onClick={handleClickOnStartYourProject}>
                                        {props.settings.startYourProjectButton[0].title}
                                    </Button>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row only="tablet mobile">
                    <Grid.Column>
                        <MobileNavigationMenu
                            items={props.settings.navigationMenu}
                            isHidden={layout.isMobileMenuVisible}
                            callToAction={props.settings.startYourProjectButton[0]}
                        />
                        <Menu inverted secondary>
                            <Menu.Item>
                                <Link to="/" aria-label={logo.alt}>
                                    <img src={StoryblokRenderingService.resize(logo, 168, 0)} alt={logo.alt} title={logo.title} />
                                </Link>
                            </Menu.Item>

                            <Menu.Menu position='right'>
                                <LanguageSwitcher className="item" />
                                <Menu.Item onClick={toggleMobileMenu}>
                                    <Icon name="bars" size="big"></Icon>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </header>
    );
};
