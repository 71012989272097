import React from "react";
import { useContext } from "react";
import { useState } from "react";

export interface LayoutContextProps {
    isMobileMenuVisible: boolean;
    setMobileMenuVisibility: (isVisible: boolean) => void;
}

export const useLayout = (): LayoutContextProps => useContext(LayoutContext);
export const LayoutContext = React.createContext<LayoutContextProps>({
    isMobileMenuVisible: false,
    setMobileMenuVisibility: () => {},
});

export const LayoutProvider: React.FC = (props) => {
    const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false);

    return (
        <LayoutContext.Provider value={{ isMobileMenuVisible, setMobileMenuVisibility }}>
            {props.children}
        </LayoutContext.Provider>
        
    );
};