import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { StoryblokContent } from '../../types';
import { Accordion, AccordionTitleProps, Transition } from 'semantic-ui-react';
import { DynamicComponent } from '../DynamicComponent/dynamicComponent';
import AnimateHeight from 'react-animate-height';

interface OwnProps {
    title: string;
    cotent: StoryblokContent[];
    index: number;
    activeIndex: number;
    handleAccordionClick: (index: number) => void;
}

export type AccordionPanelProps =
    & SbEditableContent
    & OwnProps;

export const AccordionPanel: React.FC<AccordionPanelProps> = (props) => {

    const renderedChildren = props.children || props.content?.map(component => <DynamicComponent key={component._uid} blok={component} />);
    
    const handleAccordionTitleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, data: AccordionTitleProps) => {
        props.handleAccordionClick(data.index as number);
    }

    const isActive = props.activeIndex === props.index;

    return (
        <SbEditable key={props._uid} content={props}>
            <div className={`border ${isActive ? 'active' : ''}`}>
                <Accordion.Title
                    active={isActive}
                    className="tw-py-4"
                    index={props.index}
                    onClick={handleAccordionTitleClick}
                >
                    {props.title}
                </Accordion.Title>

                <div style={{ paddingLeft: '60px', paddingBottom: '16px' }}>
                    <AnimateHeight animateOpacity duration={300} height={isActive ? 'auto' : 0}>
                        {renderedChildren}
                    </AnimateHeight>
                </div>
            </div>
        </SbEditable>
    );
};