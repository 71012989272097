import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import { StoryblokImage } from '../../types';
import { Grid, Image } from 'semantic-ui-react';
import StoryblokService from '../../utils/storyblok-service';
import StoryblokRenderingService from '../../utils/storyblok-rendering-service';

interface OwnProps {
    content: string;
    picture: StoryblokImage;
}

export interface BiographyProps {
    blok: SbEditableContent & OwnProps;
}

export const Biography: React.FC<BiographyProps> = (props) => {

    const htmlContent = StoryblokService.renderRichText(props.blok.content);
    
    return (
        <SbEditable key={props.blok._uid} content={props.blok}>
            <Grid stackable centered columns={2}>
                <Grid.Column>
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </Grid.Column>
                <Grid.Column>
                    {props.blok.picture && (
                        <Image src={StoryblokRenderingService.resize(props.blok.picture, 230, 0)} alt={props.blok.picture.alt} title={props.blok.picture.title} circular className="tw-my-0 tw-mx-auto"/>
                    )}
                </Grid.Column>
            </Grid>
        </SbEditable>
    );
};