import React from "react";
import SbEditable, { SbEditableContent } from "storyblok-react";
import { StoryblokContent } from "../../types";
import { DynamicComponent } from "../DynamicComponent/dynamicComponent";

interface OwnProps {
    content?: StoryblokContent[];
    cssClasses: string;
}

export interface GridColumnProps {
    blok: SbEditableContent & OwnProps;
}

export const GridColumn: React.FC<GridColumnProps> = (props) => {
    const { blok } = props;
    const renderedChildren = props.children || blok.content?.map(component => <div key={component._uid}><DynamicComponent key={component._uid} blok={component} /></div>);

    return (
        <SbEditable content={blok} key={blok._uid}>
            <div className={`column ${blok.cssClasses || ''}`}>
                {renderedChildren}
            </div>
        </SbEditable>
    );
};