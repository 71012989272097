import React from 'react'
import SbEditable, { SbEditableContent } from 'storyblok-react';
import StoryblokService from '../../utils/storyblok-service';

import styles from "./richText.module.css";

interface OwnProps {
    content: string;
    cssClasses: string;
}

export interface RichTextProps {
    blok: SbEditableContent & OwnProps;
}

export const RichText: React.FC<RichTextProps> = (props: RichTextProps) => {
    const htmlContent = StoryblokService.renderRichText(props.blok.content);

    return (
        <SbEditable key={props.blok._uid} content={props.blok}>
            <div className={`${props.blok.cssClasses} ${styles.richText}`} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </SbEditable>
    );
};