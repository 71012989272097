import React from 'react'
import { StoryblokLink } from '../../types';
import { Link } from 'gatsby';

interface OwnProps {
    to: StoryblokLink;
    text: string;
    className?: string;
}

export type InternalLinkProps =
    & OwnProps;

export const InternalLink: React.FC<InternalLinkProps> = (props) => {
    return (
        <Link className={`tw-text-blue tw-uppercase ${props.className || ''}`} to={props.to.cached_url}>
            {props.text}
        </Link>
    );
};